@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-color: #ffffff;
}

h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
}
h2 {
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
}
h3 {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
}
h4 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}
h5 {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}
h6 {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

p {
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  margin-bottom: 0;
}
span {
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
}
label, li, div {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}
body {
  background-color: #303030;
  color: white;
}

p {
  margin: 0
}

code {
  color: #8f8f8f;
  margin: 0;
  font-size: smaller;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: white;
}

.NavbarContainer {
  background-color: #181818;
  box-shadow: 0px 0px 20px -7px white;
}

.labelFiltri {
  margin: 0px;
  margin-bottom: 5px;
  font-size: small;
}

.filtriContainer {
  background-color: #181818;
  border-radius: 10px;
  box-shadow: 0px 0px 20px -7px #8a8a8a;
}

.cardPersona {
  width: 100%;
  min-height: 200px;
  border-radius: 10px;
  background-color: #eaeaea;
  color: black;
}
.titleCardPersona {
  background-color: #181818;
  color: gold;
  border-radius: 10px 0 0 0;
}

.picCardPersona {
  border-radius: 0 10px 10px 0;
}

.rounded10 {
  border-radius: 10px;
}

.annotazioni, .annotazioni:focus {
  resize: none;
  outline: none;
  display: block;
  width: 100%;
  padding: 10px;
  top: 0;
  font-size: 16px;
  background-color: #404040;
  color: white;
  min-height: 14em;
}

.socialPersona:hover {
  opacity: 0.9;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  transition: 0.3s;
}

.shareButton {
  color: lightgray;
}

.shareButton:hover {
  color: white;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  transition: 0.3s;
}

.asterisco {
  color: gold;
  font-weight: bold;
}

.form-control:focus {
  border-color: gold;
  box-shadow: 0px 1px 1px rgb(255, 215, 0, 0.075) inset, 0px 0px 8px rgb(255, 215, 0, 0.5);
}

.picPersona {
  position: relative;
  width: 100%;
}
.picPersona:before {
  content: "";
  display: block;
  padding-top: 100%;
}

table {
  width: 100%
}
th {
  font-size: small;
  font-weight: normal;
  color: #b7b7b7;
}

.cardStrumenti {
  background-color: #e7a100;
  height: 150px;
  border-radius: 20px;
  padding: 20px 25px 20px 25px;
  box-shadow: 7px 6px 10px #262626;
  color: #303030;
  transition: 0.5s;
}
.cardStrumenti:hover {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
  transition: 0.5s;
  color: #404040;
}
.cardStrumentiDisabled {
  background-color: #9e9e9e;
  height: 150px;
  border-radius: 20px;
  padding: 20px 25px 20px 25px;
  color: #404040;
  opacity: 0.5;
  cursor: not-allowed;
}

#dropdown-db {
  color: white;
  padding: 0;
}
.dropdown-menu {
  background-color: #303030;
}
.dropdown-item:hover {
  background-color: #181818;
}

#linkAttoriPorvvisori, #linkAttoriPorvvisori:hover {
  color: gray;
  text-decoration: none;
}

.cardVerbali {
  min-height: 120px;
  background-color: rgba(255, 204, 0, 0.02);
  width: auto;
  padding: 20px;
  border-radius: 20px;
  border: solid #ffcc00;
  box-shadow: 4px 4px 10px #4e4e4e;
}
.cardVerbali:hover {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
  transition: 0.3s;
}
.iconVerbale:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  transition: 0.3s;
}

.cardAttrezzatura {
  background-color: #f1f1f1;
  color: black;
  border-radius: 12px;
  box-shadow: 5px 5px 20px #1a1a1a;
}
.cardAttrezzatura:hover {
  background-color: #dedede;
  transition: 0.5s;
}

.iconArchivioBookingAttrezzatura {
  color: #ffcc00;
  opacity: 0.75;
  transition: 0.3s;
}
.iconArchivioBookingAttrezzatura:hover {
  cursor: pointer;
  opacity: 1;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: 0.3s;
}


.cardUtenteTeamVideo {
  background-color: #ffcc00;
  border-radius: 20px;
  color: #1a1a1a;
  padding: 13px 20px 15px 25px;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.42);
}
.cardUtenteTeamVideo:hover {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
  transition: 0.3s;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.64);
}

.cardCalendario {
  background-color: white;
  color: #181818;
}

.downloadItem {
  transition: 0.3s;
  cursor: pointer;
}
.downloadItem:hover {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
  opacity: 0.8;
  transition: 0.3s;
}

.iconTrash {
  transition: 0.3s;
}
.iconTrash:hover {
  cursor: pointer;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition: 0.3s;
}

.borderLGdanger {
  border: solid 4px red;
}
.borderLGwarning {
  border: solid 4px #ffcc00;
}
.borderLGsuccess {
  border: solid 4px green;
}


